:root {
  --poppins: "Poppins";
  --trigger__black: #133159;
  --trigger__warning: #ff264a;
  --light__blue: #3c7ef3;
  --light__blue__hover: #5890f4;
  --transition: all 0.4s linear;
  --border__bottom__1px: 1.29px solid #e5e8ef;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:focus-visible {
    outline: 0 !important;
}
.DealModule-container-parent-class h1, 
.DealModule-container-parent-class h2, 
.DealModule-container-parent-class h3, 
.DealModule-container-parent-class h4, 
.DealModule-container-parent-class h5, 
.DealModule-container-parent-class h6, 
.DealModule-container-parent-class p, 
.DealModule-container-parent-class ul, 
.DealModule-container-parent-class ol {
  margin: 0;
  padding: 0;
}

.collapsible {
  border: 0 !important;
  margin: 0 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}

.border__bottom {
  border-bottom: var(--border__bottom__1px);
}

.flex {
  display: flex !important;
}

.items-center {
  align-items: center !important;
}

.content-between {
  justify-content: space-between !important;
}

.trigger {
  /* background: #F6F7FB; */
  padding-top: 30px;
}

.trigger__topbar {
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  border-bottom: var(--border__bottom__1px);
  padding: 17px 20px 23px;
  margin-bottom: 20px;
}

.trigger__title {
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: var(--trigger__black);
}

.trigger__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--trigger__black);
}

.trigger__btn {
  font-family: var(--poppins);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  padding: 13px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: var(--transition);
}

.trigger__blueBtn {
  background: var(--light__blue);
  color: #fff;
}

.trigger__blueBtn:hover {
  background: var(--light__blue__hover);
}

.add__newFolder {
  margin-right: 31px;
}

.trigger__blueBtn>svg, .trigger__blueBtn>i {
  margin-right: 10px;
  font-size: 20px;
  width: 20px;
  height: 20px;
}

.collapsible {
  margin-bottom: 20px !important;
}

.trigger__grayBtn {
  padding: 10px 15px;
  color: var(--trigger__black);
}

.trigger__grayBtn>svg, .trigger__grayBtn>i {
  font-size: 16px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.trigger__edit {
  margin-right: 15px;
}

.trigger__grayBtn.trigger__delete {
  color: var(--trigger__warning);
}

.trigger__grayBtn.trigger__delete>svg {
  width: 13px;
}

.trigger__header {
  /* padding: 25px 20px; */
  padding: 17.5px 20px;
  border-radius: 10px 10px 10px 10px;
  background: #ffffff;
  font-family: var(--poppins);
}

.trigger__active .trigger__header {
  border-radius: 10px 10px 0 0;
}

.trigger__header svg {
  width: 20px;
  height: 20px;
}

.collapsible-body {
  padding: 30px;
  border-radius: 0 0 10px 10px;
  background: #fff;
  padding: 0;
  border-bottom: 1.29px solid #e5e8ef;
}

.trigger__active {
  display: block;
}

.trigger__agency .plus__icon, .trigger__agency .minus__icon {
  transition: all 0.4s;
}

.trigger__agency .minus__icon {
  display: none;
  opacity: 0;
}

.trigger__active .plus__icon {
  opacity: 0;
  display: none;
}

.trigger__active .minus__icon {
  opacity: 1;
  display: block;
}

.templateItems {
  padding: 30px;
  background: #fff;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  row-gap: 30px;
  display: flex;
  flex-wrap: wrap;
  max-height: 435px;
  overflow-y: auto;
}

.templateItem {
  background: #f6f7fb;
  border-radius: 10px;
  padding: 15px 20px 13px;
  position: relative;
  max-width: 168px;
  width: 100%;
  max-height: 172px;
}

.templateItem__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #133159;
  margin: 30px 0 10px;
}

.templateItem__counts {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.65);
}

.templateItem__counts span {
  margin-right: 5px;
}

.templateItem p.templateItem__counts+div{
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

.dropdownTrigger img, .dropdownTrigger svg {
  width: 5px;
  height: auto;
}

.trigger__dropdown {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 163px !important;
  right: 33px;
  top: 18px;
  z-index: 999;
  transition: all 0.4s;
}

.global__dropdown .MuiList-padding {
  padding: 0 !important;
}

.global__dropdown .MuiPopover-paper {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  width: auto !important;
}

.global__dropdown .MuiListItemIcon-root {
  min-width: unset !important;
}

.global__dropdown .MuiListItemText-root.MuiListItemText-inset {
  padding-left: 10px;
}

.global__dropdown .MuiListItemIcon-root span {
  height: 16px;
}

.global__dropdown .MuiMenuItem-root {
  display: flex !important;
  align-items: center !important;
}

.trigger__dropdown a {
  padding: 5.5px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: rgba(19, 49, 89, 0.65);
}

.trigger__dropdown a.deleteIcon {}

.trigger__dropdown a>img {
  margin-right: 10px;
}

.trigger__folderDropdown .MuiPopover-paper li {
  color: rgba(19, 49, 89, 0.65);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.trigger__folderDropdown .MuiPopover-paper li a {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* .trigger__folderDropdown .MuiPopover-paper li:last-child {
  color: #ff264a;
} */

.red__item span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  color: red;
}

/* Deluxe */

.trigger__deluxe.trigger__active .arrow__icon {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.trigger__deluxe .arrow__icon {
  transition: all 0.4s;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.listItem {
  padding: 10px 20px 10px 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trigger__deluxe .MuiSnackbar-root + .listItem,
.listItem+.listItem {
  margin-top: 20px;
  /* border-top: 0.03px solid #dddddd6b; */
}

.trigger__deluxe.trigger__active .collapsible-body {
  padding: 20px 0;
  background: #e5e5e5 !important;
}

.trigger__deluxe .listItem {
  background: #fff;
  margin-left: 20px;
  width: calc(100% - 40px);
}

.status {
  margin-left: 129px;
}

.left__list {
  display: flex;
  align-items: center;
}

.left__list>span {
  width: 200px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right__list .list__settings_btn {
  text-transform: capitalize;
  box-shadow: unset !important;
  border: 1px solid #f6f7fb;
  border-radius: 5px;
  background: transparent;
  padding: 13px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #133159;
  font-family: Poppins;
  transition: all 0.4s;
}

.right__list .MuiButton-startIcon {
  margin-right: 10px;
}

.right__list .MuiButton-endIcon {
  margin-left: 10px;
}

.right__list .list__settings_btn:hover {
  background: #133159;
  color: #fff;
}

.defaultToggle .collapsible-body {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  display: block !important;
  max-height: 0;
}

.defaultToggle.trigger__active .collapsible-body {
  opacity: 1;
  visibility: visible;
  max-height: 600px;
  overflow-y: auto;
}

.noItems .emptyRules {
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: #133159;
  padding: 21px;
  background: #ffffff !important;
}

.collapsible-body.noItems {
  border-radius: 0 !important;
}

.collapsible-body.noItems .emptyRules {
  margin-left: 20px;
  width: calc(100% - 40px);
}

.collapsible.trigger__deluxe {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}

.trigger__modal_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.trigger__modal_title.mt10pxSpace{
  margin-top: 10px;
}
.globalModalWrapper .trigger_customFormControl.alt {
  margin-top: 0 !important;
}
.trigger_customFormControl.alt .trigger__select .MuiSelect-select {
  border: 1px solid #ccd2d2 !important
}

.trigger__modal_textField {
  border: 1px solid #ccd2d2 !important;
  border-radius: 5px !important;
  padding-left: 15px !important;
  color: #133159;
  font-size: 14px !important;
  width: calc(100% - 15px) !important;
  margin: 0 !important;
}
.trigger__modal_textField::-webkit-input-placeholder {
  color: #133159;
}
.trigger__modal_textField:-ms-input-placeholder {
  color: #133159;
}
.trigger__modal_textField::-ms-input-placeholder {
  color: #133159;
}
.trigger__modal_textField::placeholder {
  color: #133159;
}
.trigger__modal_textField:focus{
  outline: 0 !important;
  box-shadow: unset !important;
}

.trigger_customFormControl {
  width: 100%;
  margin-top: 10px !important;
}

.trigger__select {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: unset !important;
  width: 295px !important;
  width: 100% !important;
  height: 50px !important;
}

.trigger__select.mxW100m15{
  max-width: calc(100% - 15px) !important;
}

.trigger__select.mxW100m15.mt20px{
  margin-top: 20px !important;
}

.trigger__select.MuiInput-underline:after, .trigger__select.MuiInput-underline:before {
  display: none;
}

.trigger__select .MuiSelect-select {
  min-height: unset !important;
  border: 1px solid #E5E8EF !important;
  padding: 12px 15px;
  background: #F6F7FB;
  border: 1px solid #E5E8EF;
  border-radius: 5px;
  color: rgba(19, 49, 89, 0.65);
  font-family: Poppins;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px;
}

.bg-white {
  background-color: white;
}



@media (max-width: 1024px) {
  .trigger__topbar {
    padding: 10px 9px 10px 20px;
  }
  .add__newFolder {
    margin-right: 15px;
  }
  .trigger__topbar .trigger__btn {
    padding: 11px 10px 11px 10px;
    letter-spacing: 0.01em;
  }
  .trigger__title {
    font-size: 16px;
    line-height: 30px;
  }
  .trigger__subtitle {
    font-size: 12px;
    line-height: 20px;
  }
  .trigger__btn {
    font-size: 12px;
    line-height: 18px;
    padding: 11px 15px;
  }
  .trigger__header svg {
    width: 16px;
    height: 16px;
  }
}

.react-confirm-alert-body h1 {
  font-size: 25px;
  margin-bottom: 10px;
}

.copy__wrapper{
  display: flex !important;
  align-items: center;
  flex-direction: row !important;
  justify-content: center;
}

.copy__wrapper button {
  width: 95px;
  height: 45px;
  background-color: var(--light__blue);
  color: #FFF;
}

.copy__wrapper button:hover {
  background-color: var(--light__blue__hover);
}

.copy__wrapper input {
  width: calc(100% - 95px) !important;
  margin: 0 !important;
}
.globalModalWrapper .trigger_customFormControl.alt.newAddition input {
  margin-right: 10px !important;
  padding-right: 10px;
  width: calc(100% - 95px) !important;
}
.trigger_customFormControl input::placeholder {
  color: #cbc9d3;
}


.textarea__wrapper  textarea{
  resize: unset;
  padding: 10px;
  border: 1px solid #ccd2d2 !important;
  border-radius: 5px !important; 
  color: #133159;
  font-size: 14px !important;
  height: 120px !important;
}

.textarea__wrapper  textarea:focus{
  border: 1px solid #ccd2d2 !important;
  outline: 0 !important;
}

.w-100{
  width: 100% !important;
}

.trigger-folder{
  background:#cbd4ff
}
.checkoutIcon__off{
  color:#FF264A!important;
}
.checkoutIcon__on{
  color: #68EBCA!important;
}

.twilio_error_code_hints_wrp{
  margin-top: 30px;
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px
}
.twilio_error_code_hints_wrp .techw_header{
  font-size: 13px;
  font-weight: 600;
  color: #133159a6
}
.twilio_error_code_hints_wrp .techw_body_wrp{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px
}
.twilio_error_code_hints_wrp .techw_bw_each_wrp{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
}
.twilio_error_code_hints_wrp .techw_bwew_dot{
  min-width: 10px;
  min-height: 10px;
  max-height: 10px;
  max-width: 10px;
  border-radius: 50px;
  background-color: #133159a6;
  margin-top: 2px;
}
.twilio_error_code_hints_wrp .techw_bwew_message{
  font-size: 11px;
  font-weight: 500;
  color: #133159a6;
}