/* React Switch Custom  Css Start*/
.trigger_active__switch .react-switch-bg,
.trigger_inactive__switch .react-switch-bg{
  background: transparent !important;
  height: 30px !important;
  width: 65px !important;
  display: flex;

}
.trigger_active__switch .react-switch-handle,
.trigger_inactive__switch .react-switch-handle{
  top: 4px !important;
  left: 4px;
  height: 22px !important;
  width: 22px !important;
}

.trigger_inactive__switch .react-switch-bg{
  border: 1px solid #FF264A;
}
.trigger_active__switch .react-switch-bg{
  border: 1px solid #68EBCA;
}
.checkoutIcon__off{
  padding: 1px 6px 0 0 !important;
}
.checkoutIcon__on{
  padding: 4px 4px 4px 10px !important;
}
