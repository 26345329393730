.MuiInputBase-root {
  font-family: Poppins;
}
.mb20px {
  margin-bottom: 20px;
}
.trigger__settings {
  margin-top: 30px;
}

.trigger__settings_left .trigger__title {
  margin: 0 30px 0 25px;
}

.trigger__title span {
  color: #3c7ef3;
}

.trigger__settings .add__newFolder {
  margin-right: 20px;
  max-width: 140px;
  width: 100%;
}

/* .MuiButton-text {
  padding: 0 !important;
} */

.trigger__settings .add__newBasicRule {
  max-width: 108px;
  width: 100%;
}

.trigger__settings .trigger__btns {
  display: flex;
}

.trigger__setting_title {
  padding: 13px 23px 13px 10px;
  background: #FFFFFF;
  border-radius: 10px 10px 0px 0px;
  height: 80px;
  display: flex;
  align-items: center;
}

.trigger__settings .selectLabel, .trigger__action_title {
  color: #133159 !important;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 30px;
}

.trigger__settings .selectLabel, .trigger__item .trigger__action_title {
  font-size: 16px !important;
  line-height: 24px !important;
}

.trigger__setting_title.alt {
  justify-content: space-between;
}

.trigger__settings_btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  text-transform: capitalize;
  color: #3C7EF3;
  padding: 14px 15px;
  background: #F6F7FB;
  border: 1px solid #E5E8EF;
  box-sizing: border-box;
  border-radius: 5px;
}

.trigger__settings_btn svg {
  margin-right: 10px;
}

.trigger__custom_row {
  display: flex;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.column1 {
  flex: 1;
}

.trigger__item {
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px;
  margin-top: 10px;
}

/* Global Select */

.trigger_customFormControl {
  width: 100%;
  margin-top: 10px !important;
}

.trigger__select {
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: unset !important;
  width: 295px !important;
  width: 100% !important;
  height: 50px !important;
}

.trigger__select.MuiInput-underline:after, .trigger__select.MuiInput-underline:before {
  display: none;
}

.trigger__select .MuiSelect-select {
  min-height: unset !important;
  border: 1px solid #E5E8EF !important;
  padding: 12px 15px;
  background: #F6F7FB;
  border: 1px solid #E5E8EF;
  border-radius: 5px;
  color: #133159;
  font-family: Poppins;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px;
}

.action__delete:focus, .trigger__select .MuiSelect-select:focus {
  background: transparent !important;
}

/* End */

.column-gap10px {
  -webkit-column-gap: 10px;
          column-gap: 10px;
}
.customAssignRow .MuiFormGroup-root{
  flex: 1;
}
/* button:focus {
  background-color: unset !important;
} */

.trigger__item_btn {
  margin-left: 25px !important;
  margin-top: 10px !important;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  letter-spacing: 0.01em !important;
  text-transform: capitalize !important;
  color: #3C7EF3 !important;
}

.trigger__item_btn:focus, .trigger__item_btn:hover {
  background: transparent !important;
}

.trigger__item_btn svg {
  margin-right: 10px;
}

.action__delete {
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center;
  letter-spacing: 0.01em !important;
  text-transform: capitalize !important;
  color: #FF264A !important;
}

.action__delete svg {
  margin-right: 10px;
  width: 13px;
  height: 16px;
}

.action__delete:hover, .action__delete:focus {
  background: transparent !important;
}

.campaign__filter_row {
  padding: 50px 0px 8px;
  position: relative;
  display: flex;
  align-items: center;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.campaign__filter_col {
  flex: 1;
}

.topRight {
  position: absolute !important;
  top: 20px;
  right: 0px;
}

.trigger__autoComplete {
  margin-top: 10px;
  min-height: unset !important;
  border: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
}

.trigger__autoComplete .MuiInputBase-formControl {
  padding: 0 !important;
}

.trigger__autoComplete .MuiInputBase-formControl .MuiInputBase-input {
  padding: 0 10px 0 15px !important;
  height: 50px !important;
  margin: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  border: 0 !important;
  background: #F6F7FB !important;
  border-radius: 5px;
}

.trigger__autoComplete .MuiInputBase-formControl .MuiInputBase-input, .PrivateNotchedOutline-legendLabelled-5>span {
  color: rgba(19, 49, 89, 0.65);
  font-family: Poppins;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 24px;
}

.trigger__autoComplete fieldset.PrivateNotchedOutline-root-3.MuiOutlinedInput-notchedOutline {
  border-color: #e5e8ef !important;
  /* top: -6px !important; */
}

.w-100 {
  width: 100% !important;
}

.trigger__btn.addAction {
  background: #F6F7FB !important;
  max-width: 148px;
  width: 100%;
  height: 54px;
  display: inline-flex;
  align-items: center;
  border: 1px solid #E5E8EF;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  margin-top: 12px;
  color: #3C7EF3;
}

.addAction svg {
  margin-right: 10px;
}

/* Actions */

.actions__append {
  padding: 20px 0 0;
}

.send__actions {
  margin-bottom: 20px;
}

.customSpace20px {
  margin: 20px 0;
}

.send__actions .MuiFormControlLabel-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: rgba(19, 49, 89, 0.65)
}

.send__actions .MuiRadio-colorSecondary.Mui-checked {
  color: #3C7EF3;
}

.send__actions .Mui-checked+.MuiFormControlLabel-label {
  color: #133159;
}

.send__actions .MuiIconButton-colorSecondary:hover {
  background-color: unset !important;
}

.send__actions .MuiIconButton-colorSecondary {
  padding: 0 5px 0 0;
}

.send__actions .MuiFormControlLabel-root {
  margin-right: 5px;
  margin-left: 0;
}

.emailOption {
  margin-left: 35px !important;
}

.radioRow {
  flex-direction: row !important;
  align-items: center;
}

.customRow {
  display: flex;
  align-items: center;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding-top: 10px;
}

.customRow .trigger_customFormControl {
  margin-top: 0 !important;
  max-width: 242px;
}

.customRow .subjectField {
  width: calc(100% - 242px);
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.customRow .MuiOutlinedInput-input {
  height: 50px !important;
  border: 0 !important;
  margin: 0 !important;
  border: 1px solid #E5E8EF !important;
  background: #F6F7FB !important;
  border-radius: 5px !important;
  padding-left: 16px !important;
}

.MuiInputBase-input:focus {
  box-shadow: unset !important;
}

.mbSpace10 {
  margin-bottom: 10px;
}

.customizeArea {
  align-items: center;
  flex-direction: row !important;
  max-width: 346px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
}

.customizeArea .useTemplate, .customizeArea .trigger_customFormControl {
  height: 40px;
  margin: 0 !important;
  flex: 1;
  border: 1px solid #E5E8EF !important;
  border-radius: 5px;
  color: #133159;
}

.customizeArea .MuiSelect-select {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  font-size: 12px;
  background: transparent !important;
  border: 0 !important;
}

.customizeArea .trigger_customFormControl {
  width: auto;
}

.customizeArea .useTemplate, .customizeArea .useTemplate span {
  color: #133159;
  font-size: 14px;
  line-height: 21px;
}

.customizeArea .useTemplate input {
  font-size: 14px;
  line-height: 21px;
}

.action__item.trigger__item+.action__item.trigger__item {
  margin-top: 30px;
}

.length__rules {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: rgba(19, 49, 89, 0.65);
}

.coloredText.MuiInputBase-root .MuiInput-input {
  color: #3C7EF3;
}

.messageTextField {
  background: #FFFFFF;
  border: 1px solid #E5E8EF !important;
  box-sizing: border-box;
  border-radius: 10px;
  height: 192px;
  overflow-y: auto;
  margin-bottom: 20px !important;
  padding: 15px !important;
  font-weight: 400 !important;
}
.messageTextField.h50px {
  height: 50px;
  color: #133159 !important;
  overflow: unset !important;
  border-radius: 5px;
}
.messageTextField.h50px .MuiInputBase-root::-webkit-input-placeholder {
  color: #133159 !important;
}
.messageTextField.h50px .MuiInputBase-root:-ms-input-placeholder {
  color: #133159 !important;
}
.messageTextField.h50px .MuiInputBase-root::-ms-input-placeholder {
  color: #133159 !important;
}
.messageTextField.h50px .MuiInputBase-root::placeholder,
.messageTextField.h50px .MuiInputBase-root {
  color: #133159 !important;
}

.messageTextField.h50px::-webkit-input-placeholder {
  color: #133159;
}
.messageTextField.h50px:-ms-input-placeholder {
  color: #133159;
}
.messageTextField.h50px::-ms-input-placeholder {
  color: #133159;
}
.messageTextField.h50px::placeholder {
  color: #133159;
}
.messageTextField .MuiInputBase-root {
  font-family: Poppins !important;
  margin: 0 !important;
  padding: 0 !important;
  color: #A5A5A5 !important;
  font-weight: 400 !important;
}

.messageTextField input.MuiInputBase-input.MuiInput-input {
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #A5A5A5;
  margin: 0;
  border: 0 !important;
  height: 100% !important;
}

.messageTextField .MuiInput-underline:after, .MuiInput-underline:before {
  display: none;
}

.messageLength {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #133159;
    position: absolute;
    right: 0;
    bottom: -24px;
}

.MuiFormGroup-root {
    position: relative;
}
.mt20px {
  margin-top: 20px;
}
.trigger__btns_group {
  -webkit-column-gap: 8px;
  column-gap: 8px;
  row-gap: 8px;
  display: flex;
  flex-direction: row !important;
  margin-bottom: 20px;

}
.trigger__btns_group .MuiButtonBase-root {
  padding: 8px 10px !important;
  border-radius: 6px;
  background: #F6F7FB;
  box-shadow: unset !important;
  color: #546376;
  /* flex: 1; */
  display: inline-flex;
  align-items: center;

}
.trigger__btns_group .MuiButtonBase-root svg {
  margin-right: 5px;
}
.trigger__btns_group .MuiButtonBase-root svg path {
  fill: #546376;
}
.trigger__btns_group .MuiButtonBase-root.deadlineIcon svg path + path {
  fill: #fff;
}

.trigger__btns_group .MuiButtonBase-root .MuiButton-label {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: inherit;
  text-transform: capitalize;
}
.trigger__btns_group .MuiButtonBase-root:hover,
.trigger__btns_group .MuiButtonBase-root.active {
  background: #3C7EF3;
  color: #FFFFFF;

}

.trigger__btns_group .MuiButtonBase-root.active svg path {
  fill: #fff;
}

.trigger__btns_group .MuiButtonBase-root.othersIcon:hover svg circle,
.trigger__btns_group .MuiButtonBase-root.othersIcon.active svg circle {
  fill: #fff;
}
.trigger__btns_group .MuiButtonBase-root:hover svg path {
  fill: #fff;
}

.trigger__btns_group .MuiButtonBase-root.deadlineIcon:hover svg path + path {
  fill: #546376;
}

.flex-row {
  flex-direction: row !important;
}
.flex-row .trigger_customFormControl ,
.flex-row .test {
  flex: 1;
}

.value__estimated {
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

.value__estimated .messageTextField.h50px {
  width: 100%;
}

.value__estimated .trigger_customFormControl {
  margin-top: 15px !important;
}

.flex-row .trigger_customFormControl {
  width: auto;
}
input.form-control.numberInputField {
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    margin: 0 !important;
    height: 46px !important;
    box-sizing: border-box;
    padding-left: 10px;
}

input.form-control.numberInputField:focus {
    box-shadow: none !important;
    border-bottom: 1px solid #f2f2f2 !important;
}
/* Media Query Start */

@media(max-width: 1440px) {
  .trigger__settings_left {
    width: calc(100% - 250px);
}
}
@media(max-width: 1390px) {
  .trigger__setting_title.alt h4 {
    width: 300px;
  }
}

@media(max-width: 1024px) {

  .value__estimated .messageTextField.h50px {
    height: 40px;
        padding-top: 10px !important;
  }
  .value__estimated .trigger_customFormControl {
    margin-top: 10px !important;
}

  .messageTextField {
    height: 130px;
  }
  .customAssignRow {
    width: 100%;
  }
  .length__rules {
    font-size: 10px;
  }

  .trigger__btns_group {
    justify-content: center;
  }
  .trigger__btns_group .MuiButtonBase-root .MuiButton-label {
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0;
  }
  .messageLength {
    display: none;
  }
  body.active__minimal_sidebar .main__content_wrapper .main__content {
    width: calc(100% - 120px);
  }
  .trigger__setting_title h4 {
    font-size: 12px;
    line-height: 20px;
  }
  .trigger__setting_title {
    height: 60px;
  }
  .trigger__setting_title h4 {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .trigger__setting_title.alt h4 {
    max-width: 123px;
  }
  .trigger__settings_btn {
    padding: 11px 7.5px;
    font-size: 12px;
    line-height: 18px;
    max-width: 101px;
  }
  .trigger__settings_btn svg {
    margin-right: 5px;
    width: 13px;
    height: 13px;
  }
  .trigger__settings .selectLabel, .trigger__item .trigger__action_title {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .trigger__select .MuiSelect-select.MuiSelect-select {
    font-size: 12px;
    line-height: 18px;
  }
  .trigger__item_btn {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .action__delete {
    font-size: 12px !important;
    line-height: 18px !important;
  }
  .trigger__btn.addAction {
    font-size: 12px !important;
    line-height: 18px !important;
    width: 101px !important;
    height: 40px !important;
  }
  .trigger__btn.addAction svg {
    margin-right: 5px;
  }
  .send__actions .MuiFormControlLabel-label {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0;
  }
  .emailOption {
    margin-left: 5px !important;
  }
  .email__actions.actions__append {
    padding: 15px 0 0;
  }
  .send__actions .MuiIconButton-colorSecondary {
    padding: 0 1px 0 0;
  }
  .customSpace20px {
    margin: 17px 0;
  }
  .customRow .trigger_customFormControl {
    max-width: 110px;
  }
  .customRow .subjectField {
    width: calc(100% - 122px);
  }
  .customRow .MuiOutlinedInput-input {
    height: 40px !important;
    font-size: 10px !important;
  }
  .action__item.trigger__item+.action__item.trigger__item {
    margin-top: 10px;
  }
  .customizeArea .useTemplate, .customizeArea .useTemplate span {
    font-size: 12px;
    line-height: 18px;
  }
  
  .customAssignRow {
    flex-wrap: wrap;
}

}



.flex__column {
  display: flex;
  flex-direction: column;
}

.d-block {
  display: block !important;
}

.flex-0 {
  flex: 0 !important;
}

.trigger__other_columns .selectLabel {
  padding-top: 25px;
}

.trigger__other_columns .campaign__filter_row {
  padding-top: 15px;
}

.trigger__other_columns .trigger__item_btn {
  padding-top: 15px !important;
}

.trigger__other_columns button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  height : 35px
}

.trigger__custom_row .input_field_with_border {
  width: 100%;
}

.trigger__custom_row .input_field_with_border input{
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  padding-left: 10px;
  box-sizing: border-box;
}

.trigger__custom_row .input_field_with_border input[type=text]:focus,
.trigger__custom_row .input_field_with_border input[type=number]:focus{
  outline: none;
  border-bottom:1px solid hsl(0, 0%, 80%);
  box-shadow: none;
}

.trigger__custom_row .input_field_with_border .MuiInput-underline:after {
  display: none;
}

.used_template_button:focus{
  background: none;
}

.used_template_button {
  height: 46px;
}

.trigger__settings .selectLabel + .customFormControl {
    width: 100%;
}

.color__trigger_select{
  color: #133159!important;
}

.color__trigger_select:hover {
  background-color: #cbd4ff!important;
}

.color__trigger_select.Mui-selected, .color__trigger_select.Mui-selected:hover{
  background-color: #cbd4ff!important;
}

.create-button-oip{
  font-family: var(--poppins);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  padding: 13px 10px;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  transition: var(--transition);
  color: #3C7EF3 !important;
}

.create-button-oip:focus {
  color: #3C7EF3 !important;
}

.webhook-card {
  box-shadow: 0 0.6px 2px 0 rgb(0 0 0 / 19%);
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.webhook-card .webhook-card-edit {
  position: absolute;
  top: 10px;
  right: 10px;
}

.truncate-fix {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}