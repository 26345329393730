.labelWrp{
	border: 1px solid #e5e8ef;
	padding: 12px 15px;
	background: #f6f7fb;
	border-radius: 5px;
	color: #133159;
	font-family: Poppins;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	height: auto;
  overflow: hidden;
  min-height: 1.1876em;
  white-space: nowrap;
  text-overflow: ellipsis;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	/* max-width: calc(100% - 15px)!important; */
}
.iconWrp{
	display: inline-flex;
}
.iconWrp svg, .iconWrp svg path{
	fill: #133159;
}
.labelInfo{
	display: flex;
	flex-direction: column;
}
.labelTitle{
	font-size: 15px;
	color: #133159;
	font-weight: 400;
}
.labelDescription{
	color: #133159;
}
/* item */
.itemWrp{
	cursor: pointer;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 6px 16px;
	margin-bottom: 1px;
}
.itemWrp:first-child{
	margin-top: 4px;
}
.itemWrp:last-child{
	margin-bottom: 4px;
}
.itemWrp:hover{
	background-color: #cbd4ff;
}
.itemSelected{
  background-color: #cbd4ff;
}
.itemTitle{
  font-size: 15px;
	color: #133159;
	font-weight: 400;
}
.itemDescription{
	color: #133159;
}
.popupWrapper{
	padding-bottom: 5px !important;
}
